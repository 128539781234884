import '../scss/main-page.scss';

// import Swiper and modules styles123
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';

import menuModule from "./modules/menu.js";
import scrollUpModule from "./modules/scrollUpModule.js";
import footerModule from "./modules/footerModule.js";
import sendPulseFormModule from "./modules/sendPulseFormModule.js";

(function ($) {
    $(document).ready(function () {
        window.$ = jQuery;

        menuModule.init();
        scrollUpModule.init();
        footerModule.init();
        sendPulseFormModule.init();

        // Swiper-ы на главной странице
        $('.posts-swiper').each(function (i, el) {
            console.log(el.dataset.swiperIndex);

            new Swiper(this, {
                modules: [Navigation],
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.button-next-' + el.dataset.swiperIndex,
                    prevEl: '.button-prev-' + el.dataset.swiperIndex,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 3,
                    }
                }
            });
        })
    });

}(jQuery));



